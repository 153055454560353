<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card no-body>
        <div class="header_card">
          <h5>Media Monitoring</h5>
        </div>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-form-group label-for="projectKeywords" label-cols-lg="3" content-cols-lg="8">
                <template #label>Keyword<small class="mandatory_txt">*</small></template>
                <b-input-group>
                  <template #append>
                    <b-button variant="light"><i class="icon-plus2"></i></b-button>
                  </template>
                  <b-form-input id="projectKeywords" placeholder="e.g. Custom Furniture" />
                </b-input-group>
              </b-form-group>
              <b-form-group label-for="projectSources" label-cols-lg="3" content-cols-lg="8">
                <template #label>Active Source<small class="mandatory_txt">*</small></template>
                <b-form-checkbox-group class="source-checkbox" id="projectSources" :options="sourceOptions"  />
              </b-form-group>
              <b-form-group label-for="projectMentions" label-cols-lg="6" content-cols-md="6" class="align-items-center">
                <template #label>Active Mentions from own media?<small class="mandatory_txt">*</small></template>
                <b-form-radio-group id="projectMentions" :options="radioYesNo"  />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="projectHashtags" label-cols-lg="3" content-cols-lg="8">
                <template #label>Hashtag <br><small class="text-muted">(optional)</small></template>
                <b-form-tags id="projectHashtags" />
              </b-form-group>
              <b-form-group label-for="projectExclude" label-cols-lg="3" content-cols-lg="8">
                <template #label>Exclude Site <small class="text-muted">(optional)</small></template>
                <b-form-input id="projectExclude" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>

        <div class="header_card">
          <h5>Integration</h5>
        </div>

        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="3"
                content-cols-lg="8"
                label-class="border-0"
              >
                <template #label>Connection<small class="mandatory_txt">*</small></template>
                <b-row>
                  <b-col md="6">
                    <div class="form-group">
                      <b-button class="bg_ig " variant="transparent" block><i class="icon-instagram mr-2"></i>
                        Instagram</b-button>
                    </div>

                    <div class="form-group">
                      <b-button class="bg_fb" variant="transparent" block><i class="icon-facebook mr-2"></i>
                        Facebook</b-button>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="form-group">
                      <b-button class="bg_tw" variant="transparent" block><i class="icon-twitter mr-2"></i>
                        Twitter</b-button>
                    </div>
                    <div class="form-group">
                      <b-button class="bg_yt" variant="transparent" block><i class="icon-youtube mr-2"></i>
                        YouTube</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>

        <div class="header_card">
          <h5>CRM Setting</h5>
        </div>

        <b-card-body>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="3"
                content-cols-lg="8"
                label-class="border-0"
              >
                <template #label>Lead Owner<small class="mandatory_txt">*</small></template>
                <b-input-group>
                  <b-form-input placeholder="e.g. Custom Furniture" />
                  <template #append>
                    <b-button variant="transparent" class="alpha-pink text-pink-800">
                      <i class="icon-cross2 mr-0"></i>
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols-lg="3"
                content-cols-lg="8"
                label-class="border-0"
              >
                <template #label>Lead Source<small class="mandatory_txt">*</small></template>
                <b-input-group>
                  <b-form-input placeholder="e.g. Custom Furniture" />
                  <template #append>
                    <b-button variant="transparent" class="alpha-pink text-pink-800">
                      <i class="icon-cross2 mr-0"></i>
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols-lg="3"
                content-cols-lg="8"
                label-class="border-0"
              >
                <template #label>Lead Status<small class="mandatory_txt">*</small></template>
                <b-input-group>
                  <b-form-input placeholder="e.g. Default" />
                  <template #append>
                    <b-button variant="transparent" class="alpha-pink text-pink-800">
                      <i class="icon-cross2 mr-0"></i>
                    </b-button>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
        <template #footer>
          <b-button class="bg-indigo-400 mr-2" variant="transparent"><i class="icon-check2  mr-2"></i> Save</b-button>
          <b-button class="bg-slate-400" variant="transparent"> Reset</b-button>
        </template>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
      sourceOptions: ['Facebook', 'YouTube', 'Instagram', 'Blog', 'Web']
    }
  }
}
</script>